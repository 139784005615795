import SlidingPanel from 'react-sliding-side-panel';
import 'react-sliding-side-panel/lib/index.css';


const LeftMenu = ({ children, ...rest }) => {


    return (
        <SlidingPanel
            {...rest}
            data-cy={'left-menu'}
        >
            {children}
        </SlidingPanel>
    )
}

export default LeftMenu