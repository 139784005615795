import MainLayout from "common/layouts/MainLayout";
import React, { useCallback, useEffect, useState } from "react";
import Partner from "modules/Partner";
import PartnerOpen from "modules/Partner/components/PartnerOpen";
import { wrapper } from "store";
import { links } from "helper";
import CartButton from "modules/Cart/components/CartButton";
import HeaderPartner from "shared/HeaderPartner";
import Card from "shared/Card";
import navRoute from "helper/navRoute";
import { usePartnerOpen } from "modules/Partner/hooks";
import HeadSeo from "shared/HeadSeo";
import DeviceDetect from "shared/DeviceDetect";
import LeftMenu, { useLeftMenu } from "shared/LeftMenu";
import SideMenu from "shared/SideMenu";
import Header from "shared/Header";
import useMap from "modules/Map/hooks/useMap";
import { useSlidePanel } from "../../../../src/shared/SlidePanel";
import { useAddress } from "../../../../src/modules/Map/hooks";
import { useAuth } from "../../../../src/modules/User/hooks";

function PartnerOpenPage() {
    const { partner, onPartnerSet, partnerSyncMiniSite } = usePartnerOpen();
    const { onMenuSet, onMenuShopSet, menuReset } = Partner.hooks.useMenu();
    const { addressLabel, map } = useMap();
    const { onOpen, isOpen, onClose } = useLeftMenu();
    const [actions, setActions] = useSlidePanel();
    const { isAuth } = useAuth();

    const { onSelectMyAddress, onSelectAddress, address } = useAddress();

    const navigation = navRoute();
    const onGoMap = useCallback(() => {
        if (address.data?.length && isAuth) {
            actions.onOpen();
        } else {
            navigation.navigate(links.abs.map);
        }
    }, [address, actions]);

    const onGoSearch = useCallback(() => {
        navigation.navigate(links.abs.search);
    }, []);

    useEffect(() => {
        partnerSyncMiniSite();
    }, []);

    useEffect(() => {
        if (partner.data.id) {
            setTimeout(async () => {
                const isShopPartner = partner.data.menuViewType === 1;
                //const baseApi = location.href;
                const baseApi = location.href;
                if (isShopPartner) {
                    const menu =
                        await Partner.services.menuService.fetchShopMenuMiniSite(
                            null,
                        );
                    onMenuShopSet(menu);
                } else {
                    const menu = await Partner.services.menuService
                        .fetchMenuMiniSite
                        //baseApi,
                        ();
                    onMenuSet(menu);
                }
            }, 300);
        }
    }, [partner.data]);

    return (
        <MainLayout isMobile data-cy={"resorany-open-page"}>
            <HeadSeo seo={partner?.data?.seo} />
            <DeviceDetect>
                <LeftMenu
                    isOpen={isOpen}
                    size={60}
                    backdropClicked={onClose}
                    type={"left"}
                >
                    <SideMenu variant={"miniSite"} />
                </LeftMenu>
                <Header
                    isHideLogo
                    onClickLeft={onOpen}
                    onClickCenter={onGoMap}
                    onClickRight={onGoSearch}
                >
                    {addressLabel || partner?.data?.pointAddress?.cityName}
                </Header>
            </DeviceDetect>
            <PartnerOpen variant={"miniSite"} />
            <DeviceDetect>
                <CartButton partnerOpenId={partner?.data?.alias} />
            </DeviceDetect>
        </MainLayout>
    );
}

/*
export const getStaticProps = async (context) => {
    const {partnerId} = context.params
    const {hostname} = context




    let config = {
        headers: {
            //'address-city': cityId
        }
    }

    const baseApi = "http://izum05.ru"

    const partner = await Partner.services.partnerService.fetchPartnerMiniSite(
        baseApi,
        config,
    );
    console.log('context', context)


    //await store.dispatch(Partner.store.partner.actions.partnerSet(partner))

    //console.log('partnerpartner', partner)


    const isShopPartner = partner?.menuViewType === 1

    console.log('isShopPartner', isShopPartner)


    let menu = null
    if(isShopPartner){
        menu = await Partner.services.menuService.fetchShopMenuMiniSite(
            baseApi,
            partnerId,
        );
    }else{
        menu = await Partner.services.menuService.fetchMenuMiniSite(
            baseApi,
            config,
        );
    }


    console.log('menumenu', menu)


    /!* await Promise.all([
         await store.dispatch(Partner.store.partner.actions.sync(partnerId, config)),
         await store.dispatch(Partner.store.menu.actions.sync(partnerId, config))
     ])
 *!/
    return {
        revalidate: 350,
        props: {
            partnerSP: partner,
            menuSP: menu,
            isShopPartner
        }
    }

};



const generatePath = async () => {
    const partners = []

    let paths = []


    partners.map(data => {

        paths.push({ params: { partnerId:data }})
    })

    return paths
}


export async function getStaticPaths() {
    const paths = await generatePath()


    console.log('paths', paths)

    return {
        paths,
        fallback: true,
    };
}
*/

export default PartnerOpenPage;
