import React, {memo} from 'react';
import {
    HeaderStyle,
    HeaderRightStyle,
    HeaderLeftStyle,
    HeaderCenterStyle,
    HeaderLogoStyle,
    HeaderLogoCenterStyle
} from "./styles";
import Typography from "shared/Typography/index";
import Button from "shared/Button/index";
import Link from "shared/Link";
import Icon from "shared/Icon";
import {links} from "helper";
//import Image from "shared/Image";
//import logo from "public/img/logoedos.svg";

const Header = ({
                        children,
                        onClickLeft,
                        onClickCenter,
                        onClickRight,
                        mb,
                        isHideLogo,
                        ...rest
                  }) => {



    return (
        <>
                {
                    !isHideLogo && (
                        <HeaderLogoStyle data-cy={'header'}>
                            <HeaderLogoCenterStyle >
                            <Link href={links.abs.home}>
                                <a>
                                    <Icon width={122} height={26} name={'Logo'}/>
                                    {/*<Image
                                        width={122} height={26}
                                        src={logo}
                                    />*/}
                                </a>
                            </Link>
                            </HeaderLogoCenterStyle >
                        </HeaderLogoStyle>
                    )
                }

            <HeaderStyle
                mb={mb}
                {...rest}
            >
                <HeaderLeftStyle data-cy={'header'}>
                    <Button onClick={onClickLeft} variant='transparent' size='normal' icon='MenuIcon'></Button>
                </HeaderLeftStyle>
                <HeaderCenterStyle onClick={onClickCenter}>
                    <Typography variant="h4" align="center">
                        {children}
                    </Typography>
                </HeaderCenterStyle>
                <HeaderRightStyle>
                    <Button onClick={onClickRight} variant='transparent' size='normal' icon='SearchIcon'></Button>
                </HeaderRightStyle>
            </HeaderStyle>
        </>
    );
};

export default memo(Header);
