import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme/index';
import Helpers from 'common/Theme/Helpers';

export const HeaderStyle = styled.div`
  ${Helpers.fullWidth};
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  ${Helpers.css.flex_sb};
  background: ${Colors.bg};
  padding:11px 10px;

  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
`;

export const HeaderRightStyle = styled.div`
`;

export const HeaderLogoStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_center};
  background: ${Colors.bg};
  padding: 16px 0;
  padding-bottom: 5px;
  height: 26px;
  box-sizing: content-box;
`;

export const HeaderLogoCenterStyle = styled.div`
  width: 122px;
  height: 26px;
  position: relative;

`;

export const HeaderLeftStyle = styled.div`
`;

export const HeaderCenterStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  ${Helpers.css.cursor_pointer};
  background: #FFFFFF;
  border-radius: 24px;
  height: 34px;
  margin: 0 5px;
  width: calc(100% - 74px);
  padding: 0 10px;
  div{
  white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
width: 100%;
  }
`;
