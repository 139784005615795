import {useState} from "react";




const useLeftMenu = (state) => {
    const [isOpen, setOpen] = useState(state)

    const onToggle = () => setOpen(!isOpen)
    const onOpen = () => setOpen(true)
    const onClose = () => setOpen(false)

    return {onToggle, isOpen, onOpen, onClose}
}

export default useLeftMenu