import React  from "react";
import {
    WrapStyle,
    LogoWrapStyle,
    LinkListStyle,
    LinkItemStyle,
    VersionWrapStyle,
} from "./styles";
import Typography from "shared/Typography";
import Image from "shared/Image";
import Link from "shared/Link";
import { links } from "helper";
import navRoute from "helper/navRoute";
import useAuth from "modules/User/hooks/useAuth";
import logoFull from "/public/img/logo_full.svg";
import AppLinks from "shared/AppLinks/AppLinks";
import {useMobileContextDetect} from "shared/DeviceDetect";


//import logoFull from "/public/img/logo_e_w.svg";
//import dynamic from "next/dynamic";

/*const preval = dynamic(
    () => import('preval.macro'),
    { ssr: false }
)*/

const SideMenu = ({ data, variant, ...rest }) => {
    const navigation = navRoute();
    const {isAuth, onLogOut} = useAuth();
    const isMobile = useMobileContextDetect();

    //const one = preval`module.exports = 1 + 2 - 1 - 1`

    const onOpenSetting = () => {
        navigation.navigate(links.abs.settings);
    };

    return (
        <>
            <WrapStyle {...rest} data-cy={'side-menu'}>

                {
                    variant !== 'miniSite' && (
                        <LogoWrapStyle>
                            <Image src={logoFull}></Image>
                        </LogoWrapStyle>
                    )
                }

                <LinkListStyle>
                    {
                        isAuth ? (
                            <>
                                {
                                    variant === 'miniSite' ? (
                                        <>
                                            <LinkItemStyle>
                                                <Link href={links.abs.home}>
                                                    <a>
                                                        <Typography variant="textBig" color="white">
                                                            Главная
                                                        </Typography>
                                                    </a>
                                                </Link>
                                            </LinkItemStyle>
                                            <LinkItemStyle>
                                                <Link href={links.abs.myOrders}>
                                                    <a>
                                                        <Typography variant="textBig" color="white">
                                                            Мои заказы
                                                        </Typography>
                                                    </a>
                                                </Link>
                                            </LinkItemStyle>
                                            <LinkItemStyle onClick={onLogOut}>
                                                <Typography variant="textBig" color="white">
                                                    Выход
                                                </Typography>
                                            </LinkItemStyle>
                                        </>
                                    ) : (
                                        <>
                                            <LinkItemStyle>
                                                <Link href={links.abs.home}>
                                                    <a>
                                                        <Typography variant="textBig" color="white">
                                                            Главная
                                                        </Typography>
                                                    </a>
                                                </Link>
                                            </LinkItemStyle>
                                            <LinkItemStyle>
                                                <Link href={links.abs.partners}>
                                                    <a>
                                                        <Typography variant="textBig" color="white">
                                                            Все рестораны
                                                        </Typography>
                                                    </a>
                                                </Link>
                                            </LinkItemStyle>
                                            <LinkItemStyle>
                                                <Link href={links.abs.myOrders}>
                                                    <a>
                                                        <Typography variant="textBig" color="white">
                                                            Мои заказы
                                                        </Typography>
                                                    </a>
                                                </Link>
                                            </LinkItemStyle>
                                            <LinkItemStyle>
                                                <Link href={links.abs.profile}>
                                                    <a>
                                                        <Typography variant="textBig" color="white">
                                                            Профиль
                                                        </Typography>
                                                    </a>
                                                </Link>
                                            </LinkItemStyle>
                                            <LinkItemStyle>
                                                <Link href={'javascript:window.jivo_api.open()'}>
                                                    <a>
                                                        <Typography variant="textBig" color="white">
                                                            Онлайн консультант
                                                        </Typography>
                                                    </a>
                                                </Link>
                                            </LinkItemStyle>
                                            <LinkItemStyle>
                                                <Link href={links.abs.faq}>
                                                    <a>
                                                        <Typography variant="textBig" color="white">
                                                            Помощь
                                                        </Typography>
                                                    </a>
                                                </Link>
                                            </LinkItemStyle>
                                            <LinkItemStyle onClick={onLogOut}>
                                                <Typography variant="textBig" color="white">
                                                    Выход
                                                </Typography>
                                            </LinkItemStyle>
                                        </>
                                    )
                                }

                            </>
                        ) : (
                            <>
                                {
                                    variant === 'miniSite' ? (
                                        <>
                                            <LinkItemStyle>
                                                <Link href={links.abs.home}>
                                                    <a>
                                                        <Typography variant="textBig" color="white">
                                                            Главная
                                                        </Typography>
                                                    </a>
                                                </Link>
                                            </LinkItemStyle>
                                            <LinkItemStyle>
                                                <Link href={links.abs.authRequest}>
                                                    <a>
                                                        <Typography variant="textBig" color="white">
                                                            Войти
                                                        </Typography>
                                                    </a>
                                                </Link>
                                            </LinkItemStyle>
                                        </>
                                    ) : (
                                        <>
                                            <LinkItemStyle>
                                                <Link href={links.abs.home}>
                                                    <a>
                                                        <Typography variant="textBig" color="white">
                                                            Главная
                                                        </Typography>
                                                    </a>
                                                </Link>
                                            </LinkItemStyle>
                                            <LinkItemStyle>
                                                <Link href={links.abs.authRequest}>
                                                    <a>
                                                        <Typography variant="textBig" color="white">
                                                            Войти
                                                        </Typography>
                                                    </a>
                                                </Link>
                                            </LinkItemStyle>
                                            <LinkItemStyle>
                                                <Link href={links.abs.partners}>
                                                    <a>
                                                        <Typography variant="textBig" color="white">
                                                            Все рестораны
                                                        </Typography>
                                                    </a>
                                                </Link>
                                            </LinkItemStyle>
                                            <LinkItemStyle>
                                                <Link href={'javascript:window.jivo_api.open()'}>
                                                    <a>
                                                        <Typography variant="textBig" color="white">
                                                            Онлайн консультант
                                                        </Typography>
                                                    </a>
                                                </Link>
                                            </LinkItemStyle>
                                            <LinkItemStyle>
                                                <Link href={links.abs.faq}>
                                                    <a>
                                                        <Typography variant="textBig" color="white">
                                                            Помощь
                                                        </Typography>
                                                    </a>
                                                </Link>
                                            </LinkItemStyle>
                                        </>
                                    )
                                }

                            </>
                        )
                    }




<br/>
<br/>

                    <LinkItemStyle>
                        <Link href={links.abs.cart}>
                            <a>
                                <Typography variant="textBig" color="white">
                                    Корзина
                                </Typography>
                            </a>
                        </Link>
                    </LinkItemStyle>
                    {/*<LinkItemStyle>
                        <Link href={links.abs.ui}>
                            <a>
                                <Typography variant="textBig" color="white">
                                    ui
                                </Typography>
                            </a>
                        </Link>
                    </LinkItemStyle>*/}
                </LinkListStyle>
                {
                    variant !== 'miniSite' && (
                        <VersionWrapStyle>
                            <Typography
                                variant="normal"
                                color="gray"
                                onDoubleClick={onOpenSetting}
                            >
                                {process.env.NEXT_PUBLIC_VERSION_LABEL} <br/>
                                Дата сборки ({process.env.NEXT_PUBLIC_BUILD_DATE})
                            </Typography>
                        </VersionWrapStyle>
                    )
                }



                <LinkItemStyle style={{marginTop: 'auto'}}>
                    <Link href={links.abs.partnerSingIn} target='_blank'>
                        <a>
                            <Typography   color="gray" mb="1.5">
                                Вход для партнеров
                            </Typography>
                        </a>
                    </Link>
                </LinkItemStyle>
                <AppLinks isHideHead isMobile={isMobile} />
            </WrapStyle>
        </>
    );
};

export default SideMenu;
